.pdf-viewer {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .upload-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .upload-btn:hover {
    background-color: #45a049;
  }
  
  .file-name {
    text-align: center;
    margin-bottom: 10px;
    font-style: italic;
    color: #666;
  }
  
  .book-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    position: relative;
  }
  
  .demo-book {
    background-size: cover;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  .page {
    background-color: #fefefe;
    color: #785e3a;
    border: 1px solid #c2b5a3;
    overflow: hidden;
  }
  
  .page img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .loading-message {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #666;
    background-color: #f0f0f0;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .upload-message {
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9;
    border: 2px dashed #ccc;
    border-radius: 8px;
    color: #666;
  }
  
  /* Styles for navigation buttons */
  .nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1000;
  }
  
  .nav-btn:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .nav-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .prev-btn {
    left: -50px;
  }
  
  .next-btn {
    right: -50px;
  }
  
  .page-number {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 14px;
    color: #333;
  }
  
  .page-info {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }

  /* Add this to your existing CSS */
.single-page {
    width: 100% !important;
  }
  
  /* Ensure other pages remain as they were */
  .page {
    width: 50%;
  }